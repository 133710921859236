.txt-descarga-stl{
    font-family: IBM Plex Sans Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #545252;
}

.box-stl1{
        background: '#FEBD18';
        border: 0;
        border-radius: 3;
        color: #000;
        height: 48;
        font-family: 'IBM Plex Sans Condensed';
        font-weight: bold;
        letter-spacing: 1.25px;
        font-size: 14px;
        line-height: 16px;
        text-align: left;
}
.texto-right{
    text-align: right;
}

.box-stl{
    background: #FFFFFF;
    /* Gris dewalt 2 */

    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

}

.margin-box{
    margin-left: 2em;
    margin-right: 2em;
}

.EmptySpace-1{
    margin-top: 4em;
}
.EmptySpace-2{
    margin-top: 10em;
}
.box-product{
    width: 100%;
    height: 10em;
    border-radius: 10px;
    border: 1px solid #FEBD18;
    box-sizing: border-box;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.h4-stl1{
    font-family: 'IBM Plex Sans Condensed','Roboto';
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-align: left;
}
.h4-stl2{
    font-family: 'IBM Plex Sans Condensed' , 'Roboto';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-align: left;
    margin-left: 1em;
}
.h3-stl-product{
    font-family: IBM Plex Sans Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    /* or 114% */
    letter-spacing: 1.25px;


    /* GRIS Dewalt */

    color: #818181;

}
.pad-client{
    padding: 1.5em 2em;
    margin: 0;
    border-bottom: 1px solid #818181;
}
.marg-pro1{
    margin-top: 0;
}
@media (max-width: 1500px) {

    .box-product{
        width: 100%;
        height: 8em;
    }
    
}
@media (max-width: 1100px) {
    .txt-descarga-stl{
        font-size: 8px;
        text-align: center;
    }
    .EmptySpace-2{
        margin-top: 7em;
    }
    .box-product{
        width: 100%;
        height: 5em;
    }
    .box-stl1{
   
        font-family: 'IBM Plex Sans Condensed';
        font-weight: bold;
        letter-spacing: 1.25px;
        font-size: 10px;
        margin-left: -1em;
    }
    .txt-descarga-stl{
        margin-right:-10em;
    }
    
}
@media (max-width: 800px) {
    .txt-descarga-stl{
        margin-right:-10em;
    }
    .EmptySpace-2{
        margin-top: 7em;
    }
    .box-product{
        width: 100%;
        height: 5em;
    }
    .box-stl1{
   
        font-family: 'IBM Plex Sans Condensed';
        font-weight: bold;
        letter-spacing: 1.25px;
        font-size: 10px;
        margin-left: -2em;
    }
    
}
@media (max-width: 450px) {
    .txt-descarga-stl{
        font-size: 13px;
    }
    .marg-pro1{
        margin-top: 2em;
    }
    .box-product{
        width: 100%;
        height: 20em;
    }
    .EmptySpace-2{
        margin-top: 5em;
    }
    .box-stl1{

        margin-left: 0em;
        font-size: 14px;
    }
}




