.Boton-main{
        background: #FEBD18;
        color: #000;
        height: 48px;
        width: 270px;
        padding: 0 30px;
        font-family: 'IBM Plex Sans Condensed';
        font-weight: bold;
        font-size: 15px;
        line-height: 19px;
        text-align : center;
}
.h3-stl-button{
    font-family: IBM Plex Sans Condensed;
    padding-top: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #000000;

}
.h3-stl-button2{
    font-family: IBM Plex Sans Condensed;
    font-style: normal;
    font-weight: bold;
    padding-top: 5px;
    font-size: 11px;
    line-height: 17px;
    text-align: center;
    color: #000000;

}

.boton-Second{
        background: #FEBD18;
        border: 0;
        border-radius: 3;
        color: '#000';
        height: 28px;
        width: 240px;
        padding: '0 30px';
}

@media (max-width: 1500px) {
    .h3-stl-button{
        font-size: 12px;
    }
}
@media (max-width: 1100px) {
    .h3-stl-button{
        font-size: 12px;
    }
    .boton-Second{
        width: 220px;
    }
}
@media (max-width: 800px) {
    .h3-stl-button{
        font-size: 8px;
    }
    .Boton-main{
        width: 170px;
    }
    .boton-Second{
        width: 150px;
        height: 35px;
    }
}
@media (max-width: 500px) {
    .h3-stl-button{
        font-size: 14px;
    }
    .Boton-main{
        width: 270px;
    }
    .boton-Second{
        width: 240px;
        height: 28px;
        
    }
}