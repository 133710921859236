.ArrowButton {
  background-color: #000000;
  margin-left: 1em;
  width: 25px;
  height: 25px;
}

.columnBorder {
  border-right: 2px solid rgba(0, 0, 0, 0.3);
  height: 85vh;
  display: flex;
  flex-direction: row;

  -webkit-box-shadow: 7px 2px 10px -1px rgba(0, 0, 0, 0.3);
  box-shadow: 7px 2px 10px -1px rgba(0, 0, 0, 0.3);

  /* outline: 5px solid rgba(0,0,0,0.18);
    outline-offset: 0px; */
}
.columnBorderProduct {
  border-right: 2px solid rgba(0, 0, 0, 0.3);
  height: 110vh;
  display: flex;
  flex-direction: row;

  -webkit-box-shadow: 7px 2px 10px -1px rgba(0, 0, 0, 0.3);
  box-shadow: 7px 2px 10px -1px rgba(0, 0, 0, 0.3);

  /* outline: 5px solid rgba(0,0,0,0.18);
    outline-offset: 0px; */
}

.OrdenesText {
  padding-left: 2em;
  padding-top: 2em;
}
.arrowPos {
  padding-top: 1em;
  padding-left: 1em;
  margin-right: 1em;
}
.Noborde {
  border: none;
  background-image: "../../Images/Arrow.png";
}

@media (max-width: 1055px) {
  .columnBorderProduct {
    height: 140vh !important;
  }
}

@media (max-width: 800px) {
  .columnBorderProduct {
    height: 100% !important;
  }
}
@media (max-height: 1000px) {
  .columnBorder {
    height: 50em;
  }
  .columnBorderProduct {
    height: 125vh;
  }
}

@media (max-height: 700px) {
  .columnBorder {
    height: 45em;
  }
}
@media (max-height: 450px) {
  .columnBorder {
    height: 48em;
  }
}

@media (max-width: 1230px) {
  .columnBorder {
    flex-direction: row;
  }
  .arrowPos {
    padding-top: 1em;
    padding-left: 0.5em;
    margin-right: 1em;
  }
  .OrdenesText {
    padding-left: 1em;
    padding-top: 2em;
  }
}
@media (max-width: 960px) {
  .columnBorder {
    flex-direction: column;
  }
  .arrowPos {
    padding-top: 0em;
    padding-left: 0.5em;
    margin-right: 1em;
  }
  .OrdenesText {
    padding-left: 1em;
    padding-top: 2em;
  }
}
@media (max-width: 500px) {
  .columnBorder {
    height: 13vh;
    flex-direction: row;
  }
  .arrowPos {
    padding-top: 1em;
    padding-left: 0.5em;
    margin-right: 1em;
  }
}
