.Tabs-stl{
    width: 95%;
    justify-content: center;
    text-align: center;
    flex-direction: row;
}

.tabs-only-stl{
    width: 70%;
}

@media (max-width: 1500px) {
    .Tabs-stl{
        width: 95%;
        justify-content: space-evenly;
        flex-direction: row;
    }
}
@media (max-width: 450px) {
    .Tabs-stl{
        width: 90%;
        justify-content: space-evenly;
        flex-direction: column;
    }
    .tabs-only-stl{
        width: 50%;
    }
}
