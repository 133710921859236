* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.imgProduct {
  width: 100%;
  height: 100%;
  padding: 5px 5px 5px 5px;
}
/* FONTS  */
.h2-stl {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #676767;
}

.h2-stl-formlog {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
}

.h3-stl {
  font-family: "IBM Plex Sans Condensed";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
}
.gris {
  color: #818181;
  font-weight: 500;
}

.gris2 {
  color: #545454;
}
.h3-stl-1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}
.h3-stl-2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #393939;
}

.h4-stl-1 {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* GRIS Dewalt */
  color: #818181;
}

.h4-stl-1-black {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  /* GRIS Dewalt */
  color: #000;
  text-align: center;
}

.h4-stl-bold {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  /* or 114% */
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #9f9f9f;
}

.h5-stl {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
  /* or 145% */
  letter-spacing: 1.25px;
  color: #5e5e5e;
}

.pad1 {
  padding-top: 2em;
  padding-left: 1em;
}
.pad2 {
  padding-top: 2em;
}
.pad3 {
  padding-top: 1em;
  padding-left: 1em;
}
.marleft {
  margin-left: 2em;
}
.martop {
  margin-top: 1em;
}
.elementsrow {
  display: flex;
  flex-direction: row;
}

/*END FONTS*/

/* HEADER */

.mainHeader {
  background-color: #000000;
}
.logoMainHeader {
  padding-left: 2em;
  padding-top: 1em;
  padding-bottom: 1em;
}

/*END HEADER*/

/*TABS*/
.tab-stl {
  padding-left: 2em;
  padding-top: 2em;
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
}

/*FOOTER*/
.img-footer {
  text-align: right;
}
/*END FOOTER*/

/* OTROS */
.EmptySpaceSmall {
  padding-top: 1em;
  padding-bottom: 1em;
}

.EmptySpaceMedium {
  padding-top: 3em;
  padding-bottom: 3em;
}

.EmptySpaceMedium2 {
  padding-top: 6em;
  padding-bottom: 6em;
}
.EmptySpaceSmall2 {
  padding-top: 0.7em;
  padding-bottom: 1em;
}
.EmptySpaceSmall3 {
  padding-top: 10px;
}

.tammedio {
  font-size: 13px;
}

.ArrowButtonProduct {
  margin-top: 2em;
  margin-left: -3em;
}

.bg-gris {
  background: #ebebeb;
}
.mar1 {
  margin-top: 2em;
  margin-left: 1em;
  padding: 10px 50px;
  color: #767272;
}

.Box-yellow {
  background-color: #febd18;
  width: 160px;
  height: 50px;
  margin-left: 1px;
  padding: 10px 10px 10px 10px;
}

.Centro {
  text-align: center;
  display: flex;
  margin: 0 auto;
}

@media (max-width: 1100px) {
  .h4-stl-bold {
    font-size: 10px;
  }
}

@media (max-width: 800px) {
  .h4-stl-bold {
    font-size: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 450px) {
  .pad2 {
    padding-top: 2em;
    margin-left: 1em;
  }
  .ArrowButtonProduct {
    margin-top: 2em;
    margin-left: 0em;
  }
  .h4-stl-bold {
    font-size: 14px;
    line-height: 16px;
  }
}

.changeClientDataWarning {
  font-family: IBM Plex Sans Condensed;
  padding: 10px;

  background: rgba(235, 235, 235, 0.5);

  /* Primary DEWALT */
  border: 1px solid #febd18;
  box-sizing: border-box;
  border-radius: 8px;
}

.warningLogo {
  /* Primary DEWALT */
  color: #febd18;
  font-size: 25px;
}
.blankwarningLogo {
  /* Primary DEWALT */
  color: rgba(235, 235, 235, 0.5);
  font-size: 25px;
}
