.text-nav-stl{
    color: #FFFFFF;
}
.text-nav-stl:link{
    font-family: IBM Plex Sans Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 14px;
    text-align: center;
    color: #FFFFFF;
}

.text-sc{
    font-family: IBM Plex Sans Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 14px;
    text-align: left;
    color: #FFFFFF;
}

.text-nav-stl:hover{
    color: #FFBE17;
}


@media (max-width: 1400px) {
    .text-sc{
        padding-left: 2em;
    }
}
@media (max-width: 800px) {
    .text-sc{
        padding-left: 5em;
    }
}
@media (max-width: 450px) {

    .nav{
        flex-direction: column;
    }
}