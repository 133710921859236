*{
    margin: 0;
    padding: 0;
}

.logoSE{
    background-image:url(../../Images/BG-header.png);
    padding-top: 3vh;
    padding-bottom: 4vh; /*O usar em*/
}

.imgLogoSE{
    justify-content: center;
    text-align: center;
    width: 100vw;
}

.footer-stl{
    background-color: #000000;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.footer-stl2{
    background-color: #000000;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    width: 100%;
}


.footer-logo{
    padding-right: 2em;
}


/* FORMULARIO */

.input-stl{
    padding-left: 1em;
    width: 356px;
    height: 47px;
}

.iconwidth{
    width: 25px;
    height: 25px;
}

.pady-5{
    padding-top: 4em;
    padding-bottom: 4em;
}
.pady-4{
    padding-top: 4em;
    padding-bottom: 4em;
}
@media (max-height: 800px) {
    .pady-5{
        padding-top: 0em;
        padding-bottom: 0em;
    }
    .pady-4{
        padding-top: 2em;
        padding-bottom: 2em;
    }
}
/*Media queries*/
@media (max-width: 800px) {
    .imgLogoSE{
        justify-content: center;
        text-align: center;
        width: 100%;
    }
  }
